import { Avatar, Button, Dropdown, Navbar as RNavbar } from 'flowbite-react';
import './navbar.css';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';



export default function Navbar() {
  const navigate = useNavigate();
  
  const { user, signOut } = useAuthenticator();
  const signOutReload = () => {
    signOut();
    navigate("/");
  }

  return (
    <RNavbar
      fluid
      className='bg-custom-baby-blue'
    >
      <RNavbar.Brand href="/">
        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
          Jessica's Blog
        </span>
      </RNavbar.Brand>
      <div className={user? "flex md:order-2" : "hidden"}>
        <Dropdown
          inline
          label={<Avatar alt="User settings" rounded/>}
        >
          <Dropdown.Header>
            <span className="block text-sm">
              {user? user.username : "Default Username"}
            </span>
            <span className="block truncate text-sm font-medium">
              {user? user.username : "none"}
            </span>
          </Dropdown.Header>
          <Dropdown.Item>
            Settings
          </Dropdown.Item>
          <Dropdown.Item onClick={signOutReload}>
            Sign out
          </Dropdown.Item>
        </Dropdown>
        <RNavbar.Toggle />
      </div>
      <div className={!user? "flex md:order-2" : "hidden"}>
        <Button href="/login">
          Login
        </Button>
        <RNavbar.Toggle />
      </div>
      <RNavbar.Collapse>
        <RNavbar.Link
          active
          href="/"
        >
          <p>
            Home
          </p>
        </RNavbar.Link>
        {user? 
          <RNavbar.Link
            active
            href="/build"
          >
            <p>
              Create
            </p>
          </RNavbar.Link>
          : null
        }
      </RNavbar.Collapse>
    </RNavbar>
  )
}