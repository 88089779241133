import "./errorpage.css";

interface ErrorPageProps {
  error?: string;
}

const ErrorPage = (props: ErrorPageProps) => {
  let text = "Looks like something went wrong."
  if (props.error) {
    text = props.error;
  }

  return (
    <>
      <div className="absolute top-0 left-0 bottom-0 right-0 h-full w-full grid place-items-center">
        <div className="px-8">
          <p className=" -ml-1 text-7xl">
            Oops!
          </p>
          <p className="text-4xl">
            {text}
          </p>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;