import './footer.css';

const Footer = (props: any) => {
  const links = [
    { text: "jwesterham2@gmail.com", link: "mailto:jwesterham2@gmail.com", Icon: () => {
      return (
      <>
        <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 8v10a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V8M1 8a1 1 0 0 1 .4-.8l8-5.75a1 1 0 0 1 1.2 0l8 5.75a1 1 0 0 1 .4.8M1 8l8.4 6.05a1 1 0 0 0 1.2 0L19 8"/>
        </svg>
      </>
      )
    }}
  ];
  return (
    <>
      <div className='flex justify-center items-center bg-custom-baby-blue'>
        {links.map((contact) => (
          <p className="font-medium my-4 mx-4">
            <a
              className="flex rounded-3xl py-2 px-2 hover:underline"
              href={contact.link}
            >
              <p className="pr-2">
                <contact.Icon />
              </p>
              {contact.text}
            </a>
          </p>
        ))}
      </div>
    </>
  );
}

export default Footer