import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Amplify, Hub } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';

import awsExports from './aws-exports';
import reportWebVitals from './reportWebVitals';
import { MainBlog, Login, Post, postLoader, summaryLoader, Build, buildLoader } from './routes';
import { ErrorPage, Footer, Navbar, RequireAuth } from './components';
import BlogAPI from './api/BlogAPI';

import './index.css';
import '@aws-amplify/ui-react/styles.css';

Hub.listen('auth', async (data: any) => {
  switch (data.payload.event) {
    case 'signIn':
      await BlogAPI.refreshToken();
      break;
  }
});
Amplify.configure(awsExports);

const PageContainer = (props: {Page: (props: any) => JSX.Element}) => {
  return <>
    <div className="flex flex-col min-h-screen bg-custom-muted-purple">
      <Navbar />
      <div className="grow">
        <div className='flex flex-row justify-center items-center'>
          <div className="max-w-screen-lg px-12">
            <props.Page />
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  </>
};

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <PageContainer Page={MainBlog} />,
        loader: summaryLoader,
      },
      {
        path: "/login",
        element: <PageContainer Page={Login} />,
      },
      {
        path: "/build",
        element: <RequireAuth>
          <PageContainer Page={Build} />
        </RequireAuth>,
        loader: buildLoader,
      },
      {
        path: "/post/:id",
        element: <PageContainer Page={Post} />,
        loader: postLoader,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Authenticator.Provider>
    <RouterProvider router={router} />
  </Authenticator.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
