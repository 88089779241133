

import { Card } from 'flowbite-react';

export interface BlogPostProps {
  postId: number;
  imgSrc?: string;
  title: string;
  description: string;
  author?: string;
}

export const BlogPost = (props: BlogPostProps) => {
  const date = new Date(props.postId);
  const dateStr = date.toDateString().split(' ').slice(1).join(' ');
  const authorStr = props.author? `By: ${props.author}` : undefined;
  return <>
    <Card
      imgAlt="..."
      imgSrc={props.imgSrc}
    >
      <p className="text-3xl font-bold tracking-tight text-gray-900 dark:text-white">
        {props.title}
      </p>
      <p className="text-gray-400 -mt-3">{dateStr}<br/>{authorStr}</p>
      <p></p>
      <p className="font-normal text-gray-700 dark:text-gray-400">
        <p>
          {props.description}
        </p>
      </p>
    </Card>
  </>
}

const BlogList = (props: any) => {
  const blogDetails = props.blogPostPropsList as BlogPostProps[] || [];
  return (
    <div>
      {blogDetails.length !== 0 ? null : <>
        <div className='flex justify-center items-center'>
          No posts yet!
        </div>
      </>}
      {blogDetails.map((blogPostProps: BlogPostProps) => {
        return <>
          <div className="py-2">
            <a href={"/post/" + blogPostProps.postId} rel="noreferrer">
              <BlogPost {...blogPostProps} />
            </a>
          </div>
        </>
      })}
    </div>
  )
}

export default BlogList