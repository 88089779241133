import './mainblog.css';
import { BlogList } from '../../components';
import { BlogPostProps } from '../../components/bloglist/BlogList';
import { useLoaderData } from 'react-router-dom';
import BlogAPI from '../../api/BlogAPI';

export async function loader({ params }: any): Promise<BlogPostProps[]> {
  const api = BlogAPI.buildBlogAPI()
  const resp = await api.getRecentsSummaries();
  const props: BlogPostProps[] = resp!.data.items.map((e: any) => {
    return {
      postId: e.createdAt,
      title: e.title,
      description: e.description,
      imgSrc: undefined,
      author: e.author,
    } as BlogPostProps;
  });
  return props;
}

function MainBlog() {
  const postDetails = useLoaderData() as BlogPostProps[];

  return (
    <>
      <div className="">
        <BlogList blogPostPropsList={postDetails}/>
      </div>
    </>
  );
}

export default MainBlog;
