import { useLoaderData } from 'react-router-dom';

import './post.css';
import BlogAPI from '../../api/BlogAPI';
import DOMPurify from 'dompurify';

interface PostInfo {
  id: number;
  imgSrc?: string[];
  title: string;
  text: string;
  author?: string;
}

export async function loader({ params }: any): Promise<PostInfo> {
  const api = BlogAPI.buildBlogAPI()
  const resp = await api.getPost(params.id);
  if (resp)
    return {
      id: resp.createdAt,
      title: resp.title,
      text: resp.content,
      author: resp.author,
    };
  else
    throw new Error();
}

const Post = (props: any) => {
  const postInfo = useLoaderData() as PostInfo;
  const date = new Date(postInfo.id);
  const dateStr = date.toDateString().split(' ').slice(1).join(' ');
  const author = postInfo.author ? `By: ${postInfo.author}` : undefined;

  return <>
    <div className='flex justify-center items-center'>
      <div>
        <div className='text-7xl font-bold py-4'>
          {postInfo.title}
        </div>
        <div className="text-gray-600 py-4">
          <p>{dateStr}</p>
          <p>{author}</p>
        </div>
      </div>
    </div>
    <div
      id="post-content"
      dangerouslySetInnerHTML={{ __html: (DOMPurify.sanitize(postInfo.text)) }}
    />
  </>
}

export default Post