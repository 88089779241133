import { Auth } from "aws-amplify";
import axios from "axios";

interface PostInfo {
  postTitle: string;
  postDescription: string;
  postContent: string;
  postAuthor: string;
}
interface GetPostResponse {
  PostsPartition: number;
  createdAt: number;
  title: string;
  content: string;
  author: string;
}
class BlogAPI {
  private endpoint: string;
  private static jwtToken: string;

  constructor(url: string) {
    this.endpoint = url;
  }

  static async refreshToken() {
    try {
      let res = await Auth.currentSession();
      let idToken = res.getIdToken();
      this.jwtToken = idToken.getJwtToken();
    }
    catch(e) {
      console.log("Cannot retrieve user info. Not logged in.")
    }
  }

  async getPost(createdAt: number): Promise<GetPostResponse | undefined> {
    try {
      const url = new URL("/prod/posts", this.endpoint);
      url.searchParams.append("createdAt", `${createdAt}`);
      
      const resp: {data: {item: any}} = await axios.get(url.toString());
      
      return resp.data.item;
    }
    catch(e) {
      console.error(e);
    }
  }

  // Must be logged in as admin
  async makePost(postInfo: PostInfo) {
    if(!BlogAPI.jwtToken)
      await BlogAPI.refreshToken();

    try {
      const url = new URL("/prod/posts", this.endpoint);
      const resp = await axios.put(url.toString(), postInfo, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': BlogAPI.jwtToken,
          }
        });
      return resp;
    }
    catch(e) {
      console.error(e);
    }
  }

  async getRecentsSummaries(pageSize: number = 5, lastEvaluated?: number) {
    try {
      const url = new URL("/prod/posts-summary", this.endpoint);
      if(lastEvaluated) url.searchParams.append("LastEvaluatedKey", `${lastEvaluated}`);
      url.searchParams.append("pageSize", `${pageSize}`);
      
      const resp = await axios.get(url.toString());
      return resp;
    }
    catch(e) {
      console.error(e);
    }
  }

  static buildBlogAPI(): BlogAPI {
    return new BlogAPI("https://ksknw6kag1.execute-api.us-east-1.amazonaws.com");
  }
}

export default BlogAPI;