import { useLocation, Navigate, useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';

function RequireAuth({children}: {children: any}) {
  const location = useLocation();
  const navigate = useNavigate();
  const { route } = useAuthenticator((context) => [context.route]);
  if (route !== 'authenticated') {
    navigate('/login');
  }
  return children;
}

export default RequireAuth;