import { useAuthenticator } from '@aws-amplify/ui-react';
import {
  useFormContext,
  FormProvider,
  useForm,
  useController
} from 'react-hook-form';
import {
  Button,
  Label,
  TextInput,
  CustomFlowbiteTheme,
} from "flowbite-react";
import * as DOMPurify from 'dompurify';
import { Editor } from '@tinymce/tinymce-react';
import './build.css';
import BlogAPI from '../../api/BlogAPI';
import { Auth } from 'aws-amplify';
import { useLoaderData } from 'react-router-dom';

type UserInfo = {
  username: string;
  attributes: {
    email: string;
  };
};
export async function loader({ params }: any): Promise<UserInfo> {
  const resp = await Auth.currentUserInfo();
  if (resp)
    return resp;
  else
    throw new Error();
}

const Build = (props: any) => {
  const { route } = useAuthenticator((context) => [context.route]);

  const BuildPage = () => {
    return <>
      <div className="">
        <PostBuilder />
      </div>
    </>
  };

  const NotAuthedPage = () => {
    return <>
      <div>
        Loading...
      </div>
    </>
  };

  return route === 'authenticated' ? <BuildPage /> : <NotAuthedPage />;
}

const removeRelativeTheme: CustomFlowbiteTheme["textInput"] = {
  field: {
    base: "w-full", // originally "relative w-full"
    input: {
      sizes: {
        custom: "sm:text-md p-2.5",
      },
    },
  },
};

type HtmlEditorProps = {
  name: string;
  required?: boolean;
};
const HtmlEditorComponent = ({ name, required }: HtmlEditorProps) => {
  const { control } = useFormContext();
  const rules = {
    required
  };
  const {
    field: { onChange, ...field }
  } = useController({ control, name, rules });

  return <>
    <Editor
      {...field}
      onEditorChange={onChange}
      init={{
        branding: false,
        height: 480,
        toolbar: [
          "redo undo",
          "blocks fontfamily fontsizeinput",
          "bold italic underline strikethrough",
          'link image emoticons',
          "alignjustify alignleft aligncenter alignright alignnone",
          "forecolor backcolor",
          "indent outdent lineheight",
          "table subscript superscript hr blockquote",
          "copy paste cut remove",
          "help",
          "restoredraft code preview fullscreen",
        ].join(" | "),
        fontsize_formats: "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
        plugins: [
          'autosave',
          'preview',
          "code",
          'emoticons',
          'fullscreen',
          'help',
          'hr',
          'image',
          "link",
          "table",
        ],
        autosave_restore_when_empty: true,
      }}
    />
  </>;
};

type FormData = {
  title: string;
  description: string;
  author: string;
  content: string;
};
const PostBuilder = () => {
  const userInfo = useLoaderData() as UserInfo;
  const methods = useForm<FormData>();
  const api = BlogAPI.buildBlogAPI();

  const onSubmit = methods.handleSubmit(async (data) => {
    const postContent = DOMPurify.sanitize(data.content);
    const textOnly = new DOMParser().parseFromString(data.content, 'text/html').body.textContent || "";
    const desc = data.description ? data.description : textOnly.slice(0, 100) + "...";
    await api.makePost({
      postTitle: DOMPurify.sanitize(data.title),
      postDescription: desc,
      postContent: postContent,
      postAuthor: data.author,
    });
    window.location.reload();
  });

  return (
    <>
      <FormProvider {...methods}>
        <form id="questionform" className="flex flex-col gap-4 overflow-auto p-1" onSubmit={onSubmit}>

          <div className="mb-2">
            <div className="mb-2 block">
              <Label htmlFor="title" value="Title" />
              <span className="text-red-600"> *</span>
            </div>
            <TextInput
              theme={removeRelativeTheme}
              placeholder="My Blog Post"
              sizing="custom"
              required={true}
              {...methods.register("title")}
            />
          </div>

          <div className="mb-2">
            <div className="mb-2 block">
              <Label htmlFor="description" value="Description" />
            </div>
            <TextInput
              theme={removeRelativeTheme}
              placeholder="This is about..."
              sizing="custom"
              {...methods.register("description")}
            />
          </div>

          <div className="mb-2">
            <div className="mb-2 block">
              <Label htmlFor="author" value="Author" />
            </div>
            <TextInput
              theme={removeRelativeTheme}
              placeholder="John Smith"
              sizing="custom"
              defaultValue={userInfo.username}
              {...methods.register("author")}
            />
          </div>

          <div>
            <div className="mb-2 block">
              <Label htmlFor="content" value="Content" />
              <span className="text-red-600"> *</span>
            </div>
            <HtmlEditorComponent name="content" />
          </div>

          <Button type="submit">Submit</Button>

        </form>
      </FormProvider>
    </>
  );
};

export default Build;